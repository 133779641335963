<template>
  <div id="ResearchInstitute">
    <h1>研究院</h1>
  </div>
</template>

<script>
export default {
  name: 'ResearchInstitute',
};
</script>

<style lang="scss" scoped></style>
